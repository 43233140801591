import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../components/axiosInstance';
import { clearCookie, getCookie } from '../../components/Cookies';
import useMessage from '../../components/useMessage';

const Picture = () => {
    const navigate = useNavigate();
    const { id, key } = useParams();

    const [title, setTitle] = useState('');
    const [newFiles, setNewFiles] = useState([]);
    const [loading, setLoading] = useState(false); // Add loading state
    const { showError, showSuccess } = useMessage();

    const handleTitleChange = event => {
        setTitle(event.target.value);
    };

    const handleFileChange = event => {
        const files = Array.from(event.target.files);
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

        const validFiles = files.filter(file => {
            if (!allowedTypes.includes(file.type)) {
                showError(
                    `Unsupported file type: ${file.type}. Only JPEG, PNG, and GIF are allowed.`
                );
                return false;
            }
            return true;
        });

        if (validFiles.length > 0) {
            setNewFiles(prevNewFiles => [...prevNewFiles, ...validFiles]);
        }
    };

    const handleSubmit = async () => {
        setLoading(true); // Start loading
        const formData = new FormData();
        formData.append('title', title);
        formData.append('user', key);
        formData.append('content_type', 'image');

        // Append new image files
        newFiles.forEach((file, index) => {
            formData.append('media', file);
        });

        const url = id
            ? `/digiboard/content/${id}/?key=${key}`
            : `/digiboard/content/?key=${key}`;
        const method = id ? 'put' : 'post';
        const message = id
            ? 'Picture updated Successfully'
            : 'Picture added Successfully';

        try {
            await axiosInstance[method](url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            navigate(`/${key}/addcontent`);
            showSuccess(message);
        } catch (error) {
            if (error.response && error.response.data) {
                showError(error.response.data);
            }
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const fetchItem = useCallback(async () => {
        try {
            const response = await axiosInstance.get(
                `/digiboard/content/${id}/?key=${key}`
            );
            const { title } = response.data;
            setTitle(title);
        } catch (e) {
            console.log(e);
        }
    }, [id, key]);

    useEffect(() => {
        if (id) {
            fetchItem();
        }
    }, [fetchItem, id]);

    const cookieKey = getCookie('key');
    useEffect(() => {
        if (!(key === cookieKey)) {
            clearCookie('token');
            navigate(`/${key}`);
        }
    });

    return (
        <Container>
            <Box
                mt={8}
                maxWidth={'730px'}
                sx={{
                    p: 1.5,
                    mx: 'auto',
                    borderLeft: '4px solid #46A957',
                    background: '#F9F9F9',
                }}>
                <Grid container display='flex' alignItems='center'>
                    <Grid item lg={4} xs={12}>
                        <Typography px={2}>Überschrift</Typography>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <TextField
                            fullWidth
                            placeholder='Title'
                            variant='outlined'
                            size='small'
                            autoComplete='off'
                            multiline
                            value={title}
                            onChange={handleTitleChange}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sx={{ mt: { xs: 4, lg: 4 } }}>
                        <Typography px={2}>Select Pictures</Typography>
                    </Grid>
                    <Grid item lg={8} xs={12} sx={{ mt: { xs: 0, lg: 4 } }}>
                        <TextField
                            fullWidth
                            type='file'
                            size='small'
                            inputProps={{ multiple: true }}
                            onChange={handleFileChange}
                        />
                    </Grid>
                </Grid>
                <Box textAlign='center'>
                    <Button
                        variant='outlined'
                        sx={{ mt: 6, width: '40%', fontWeight: 'bold' }}
                        onClick={handleSubmit}
                        disabled={loading} // Disable the button when loading
                    >
                        {loading ? <CircularProgress size={24} /> : 'Speichern'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Picture;
