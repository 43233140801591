import React from 'react';
import Navbar from './Navbar';
import { CssBaseline } from '@mui/material';
import useSnack from '../utilities/useSnack';

export const SnackContext = React.createContext();

const Header = ({ children }) => {
    const { SnackBar, showMessage } = useSnack();

    return (
        <>
            <CssBaseline />
            <SnackContext.Provider value={{ showMessage }}>
                <Navbar />
                {children}
                {SnackBar}
            </SnackContext.Provider>
        </>
    );
};

export default Header;
