import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    IconButton,
    Modal,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Card,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SettingsIcon from '@mui/icons-material/Settings';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import axiosInstance from '../components/axiosInstance';
import { clearCookie, getCookie, setCookie } from '../components/Cookies';
import useMessage from '../components/useMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderLeft: '4px solid #46A957',
    boxShadow: 24,
    p: 3,
};

const Addcontent = () => {
    const { key } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [contentId, setContentId] = useState(null);
    const [id, setId] = useState(null);
    const [contents, setContents] = useState([]);
    const [dopen, setDOpen] = useState(false);
    const [delopen, setDelOpen] = useState(false);
    const [delopencontent, setDelOpencontent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const { showError, showSuccess } = useMessage();
    const [hoveredImage, setHoveredImage] = useState(null); // State to track hovered image
    const [loadingguestbook, setLoadingguestbook] = useState(false);
    const [loadingcontent, setLoadingcontent] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClickOpen = () => setDOpen(true);
    const handleClickClose = () => setDOpen(false);
    const handleClickOpendel = id => {
        setDelOpen(true);
        setContentId(id);
    };
    const handleClickOpendelcontent = id => {
        setDelOpencontent(true);
        setId(id);
    };
    const handleClickClosedel = () => setDelOpen(false);
    const handleClickClosedelcontent = () => setDelOpencontent(false);

    console.log(hoveredImage);
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link'],
            ['clean'],
        ],
    };

    const fetchItems = useCallback(async () => {
        try {
            const response = await axiosInstance.get(
                `/digiboard/content/?key=${key}`
            );
            setContents(response.data);
        } catch (e) {
            console.log(e);
        }
    }, [key]);

    const deleteItems = useCallback(async () => {
        setLoadingguestbook(true);
        try {
            await axiosInstance.delete(
                `/digiboard/guestbook-entry/${contentId}/`
            );
            setDelOpen(false);
            fetchItems();
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingguestbook(false);
        }
    }, [contentId, fetchItems]);

    const deleteItemsContent = useCallback(async () => {
        setLoadingcontent(true);
        try {
            await axiosInstance.delete(`/digiboard/content/${id}/?key=${key}`);
            setDelOpencontent(false);
            fetchItems();
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingcontent(false);
        }
    }, [id, fetchItems, key]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
    ];

    const contentType = {
        text: 'text',
        image: 'picture',
        video: 'video',
        photowall: 'photowall',
        guestbook: 'guestbook',
    };

    const moveContent = async (currentIndex, direction, id) => {
        const newIndex = currentIndex + direction;
        if (newIndex < 0 || newIndex >= contents.length) return;

        const newContents = [...contents];
        const currentContent = newContents[currentIndex];
        newContents[currentIndex] = newContents[newIndex];
        newContents[newIndex] = currentContent;

        try {
            // Update the order of the two items in the backend
            await axiosInstance.patch(
                `/digiboard/content/${newContents[currentIndex].id}/?key=${key}`,
                {
                    order: currentIndex + 1,
                }
            );
            await axiosInstance.patch(
                `/digiboard/content/${newContents[newIndex].id}/?key=${key}`,
                {
                    order: newIndex + 1,
                }
            );

            setContents(newContents);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.patch(
                `/user/update-password/?key=${key}`,
                {
                    newPassword,
                }
            );
            const { token } = response;
            setCookie('token', token); // Set token in cookie
            setDOpen(false); // Close the modal upon successful password update
            window.location.reload();
            navigate(`/${key}`);
        } catch (err) {
            setError('Incorrect password'); // Handle incorrect credentials error
        } finally {
            setLoading(false);
        }
    };

    const cookieKey = getCookie('key');
    useEffect(() => {
        if (!(key === cookieKey)) {
            clearCookie('token');
            navigate(`/${key}`);
        }
    });

    const handleImageHover = index => {
        setHoveredImage(index);
    };

    const handleImageHoverLeave = () => {
        setHoveredImage(null);
    };

    const handleImageRemove = useCallback(
        async (contentId, imageId) => {
            try {
                await axiosInstance.delete(
                    `/digiboard/content/${contentId}/?key=${key}&id=${imageId}`
                );
                fetchItems();
                showSuccess('Photo Delete Successfully');
            } catch (error) {
                if (error.response && error.response.data) {
                    showError(error.response.data);
                }
            }
        },
        [fetchItems, key, showSuccess, showError]
    );

    const onDragEnd = async result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }
        if (source.index === destination.index) return;

        // Assuming draggableId is the index of the content item in the contents array
        const contentIndex = parseInt(destination.droppableId);
        const contentToUpdate = contents[contentIndex];
        const data = [...contentToUpdate.files];
        const [removed] = data.splice(source.index, 1);
        data.splice(destination.index, 0, removed);

        const updatedContent = { ...contentToUpdate, files: data };

        // Update the contents array with the modified content
        const newContents = [...contents];
        newContents[contentIndex] = updatedContent;
        setContents(newContents);

        // Prepare data with file ID, actual index, and changed index
        const reorderInfo = {
            fileId: removed.id,
            actualIndex: source.index,
            changedIndex: destination.index,
        };

        console.log(reorderInfo);
        // Logic to update backend with new order
        try {
            await axiosInstance.patch(
                `/digiboard/content/${contentToUpdate.id}/?key=${key}`,
                { reorder_info: reorderInfo }
            );
            fetchItems();
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };

    return (
        <Container sx={{ my: 2 }}>
            <Box sx={{ textAlign: 'right', mt: 5 }}>
                <IconButton onClick={handleOpen}>
                    <AddIcon />
                </IconButton>
                <NavLink to={`/${key}`}>
                    <IconButton sx={{ mx: 2 }}>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                </NavLink>
                <IconButton onClick={handleClickOpen}>
                    <SettingsIcon />
                </IconButton>
            </Box>

            {/* Render Content Items */}
            {contents.map((content, index) => (
                <Box
                    key={index}
                    mt={3}
                    maxWidth={'730px'}
                    sx={{
                        mx: 'auto',
                        borderLeft: '4px solid #46A957',
                        background: '#F9F9F9',
                        p: 2,
                    }}>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{ py: 2 }}>
                        <Typography variant='h4' sx={{ color: '#46A957' }}>
                            {content.title}
                        </Typography>
                        <Box>
                            <IconButton
                                onClick={() =>
                                    handleClickOpendelcontent(content.id)
                                }>
                                <DeleteIcon />
                            </IconButton>
                            <NavLink
                                to={`/${key}/addcontent/${
                                    contentType[content.content_type]
                                }/${content.id}`}>
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </NavLink>
                        </Box>
                        <Box>
                            {/* Move Up Button */}
                            <IconButton
                                onClick={() =>
                                    moveContent(index, -1, content.id)
                                }
                                disabled={index === 0}>
                                <ArrowUpwardIcon />
                            </IconButton>
                            {/* Move Down Button */}
                            <IconButton
                                onClick={() =>
                                    moveContent(index, 1, content.id)
                                }
                                disabled={index === contents.length - 1}>
                                <ArrowDownwardIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box mt={3}>
                        {/* Render Content Based on Type */}
                        {content.content_type === 'text' ||
                        content.content_type === 'guestbook' ? (
                            <Box mt={5} maxWidth={'500px'}>
                                <ReactQuill
                                    value={content.description}
                                    modules={modules}
                                    formats={formats}
                                    readOnly
                                    className='richtextWrap'
                                />
                            </Box>
                        ) : content.content_type === 'image' ||
                          content.content_type === 'photowall' ? (
                            <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId={`${index}`}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}>
                                                {content.files.map(
                                                    (img, imgIndex) => (
                                                        <Draggable
                                                            key={img.id}
                                                            draggableId={img.id.toString()}
                                                            index={imgIndex}>
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => (
                                                                <Box
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    sx={{
                                                                        maxWidth:
                                                                            '100%',
                                                                        ml: 2,
                                                                        position:
                                                                            'relative',
                                                                        '&:hover .closeButton':
                                                                            {
                                                                                display:
                                                                                    'block',
                                                                            },
                                                                    }}
                                                                    onMouseEnter={() =>
                                                                        handleImageHover(
                                                                            imgIndex
                                                                        )
                                                                    }
                                                                    onMouseLeave={
                                                                        handleImageHoverLeave
                                                                    }>
                                                                    <img
                                                                        src={`${img.image}`}
                                                                        alt='noimage'
                                                                        style={{
                                                                            width: 200,
                                                                            maxWidth:
                                                                                '100%',
                                                                        }}
                                                                    />
                                                                    <IconButton
                                                                        className='closeButton'
                                                                        sx={{
                                                                            display:
                                                                                'none',
                                                                            position:
                                                                                'absolute',
                                                                            top: 0,
                                                                            right: 0,
                                                                            color: 'red',
                                                                        }}
                                                                        onClick={() =>
                                                                            handleImageRemove(
                                                                                content.id,
                                                                                img.id
                                                                            )
                                                                        }>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Box>
                        ) : content.content_type === 'video' ? (
                            <Box sx={{ maxWidth: '100%' }}>
                                {content.videos.map((vid, vidIndex) => (
                                    <video
                                        key={vidIndex}
                                        controls
                                        width={600}
                                        style={{ maxWidth: '100%' }}>
                                        <source
                                            src={`${vid.video_file}`}
                                            type='video/mp4'
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                ))}
                            </Box>
                        ) : null}
                    </Box>
                    {/* Render Sub-Guestbooks */}
                    {content.guestbook_entries &&
                        content.guestbook_entries.length > 0 && (
                            <Box mt={3}>
                                {content.guestbook_entries.map(
                                    (subGuestbook, subIndex) => (
                                        <Card variant='outlined' sx={{ p: 1 }}>
                                            <Box
                                                display='flex'
                                                justifyContent='space-between'
                                                alignItems='center'>
                                                <Typography variant='h5'>
                                                    {subGuestbook.title}
                                                </Typography>
                                                <Box>
                                                    <IconButton
                                                        onClick={() =>
                                                            handleClickOpendel(
                                                                subGuestbook.id
                                                            )
                                                        }>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>

                                            {/* Render Sub-Guestbook Description */}
                                            <Box>
                                                <Typography variant='body1'>
                                                    {subGuestbook.description}
                                                </Typography>
                                            </Box>
                                        </Card>
                                    )
                                )}
                            </Box>
                        )}
                </Box>
            ))}

            {/* modal open */}
            <Modal
                open={open}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <Box sx={style}>
                    <Typography
                        sx={{
                            color: '#46A957',
                            letterSpacing: '1px',
                            fontWeight: 'bold',
                        }}
                        id='modal-modal-title'
                        variant='h5'
                        textAlign='center'>
                        Add content
                    </Typography>
                    <Box mt={3}>
                        <NavLink to={`/${key}/addcontent/text`}>
                            <Button fullWidth variant='outlined'>
                                Text
                            </Button>
                        </NavLink>
                        <NavLink to={`/${key}/addcontent/picture`}>
                            <Button fullWidth variant='outlined' sx={{ my: 2 }}>
                                Picture
                            </Button>
                        </NavLink>
                        <NavLink to={`/${key}/addcontent/video`}>
                            <Button fullWidth variant='outlined'>
                                Video
                            </Button>
                        </NavLink>
                        <NavLink to={`/${key}/addcontent/photowall`}>
                            <Button fullWidth variant='outlined' sx={{ my: 2 }}>
                                Photo wall
                            </Button>
                        </NavLink>
                        <NavLink to={`/${key}/addcontent/guestbook`}>
                            <Button fullWidth variant='outlined'>
                                Guestbook
                            </Button>
                        </NavLink>
                        <Button
                            fullWidth
                            variant='outlined'
                            sx={{ mt: 5 }}
                            onClick={handleClose}>
                            Back
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {/* dialog */}
            <Dialog
                open={dopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClickClose}
                aria-describedby='alert-dialog-slide-description'>
                <DialogTitle>{'Change Password'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        id='password'
                        label='New Password'
                        type='password'
                        fullWidth
                        variant='standard'
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                    />
                    {error && <Typography color='error'>{error}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClose}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={delopencontent}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClickClosedelcontent}
                aria-describedby='alert-dialog-slide-description'>
                <DialogTitle>{'Delete Content'}</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this Content?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClosedelcontent}>Cancel</Button>
                    <Button onClick={deleteItemsContent} color='error'>
                        {loadingcontent ? (
                            <CircularProgress size={24} />
                        ) : (
                            'Delete'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* delete guestbook */}
            <Dialog
                open={delopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClickClosedel}
                aria-describedby='alert-dialog-slide-description'>
                <DialogTitle>{'Delete Content'}</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this content?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClosedel}>Cancel</Button>
                    <Button onClick={deleteItems} color='error'>
                        {loadingguestbook ? (
                            <CircularProgress size={24} />
                        ) : (
                            'Delete'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Addcontent;
