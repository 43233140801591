import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../components/axiosInstance';
import { clearCookie, getCookie } from '../../components/Cookies';
import useMessage from '../../components/useMessage';

const Video = () => {
    const navigate = useNavigate();
    const { id, key } = useParams();
    const { showError, showSuccess } = useMessage();

    const [title, setTitle] = useState('');
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleTitleChange = event => {
        setTitle(event.target.value);
    };

    const handleVideoChange = event => {
        const file = event.target.files[0];
        const allowedTypes = [
            'video/mp4',
            'video/avi',
            'video/mpeg',
            'video/mov',
            'video/wmv',
        ];

        if (file && !allowedTypes.includes(file.type)) {
            showError(
                'Unsupported file type. Only mp4, avi, mpeg, mov, and wmv are allowed.'
            );
            event.target.value = null; // Reset the input value
            return;
        }

        setVideo(file);
        console.log(file);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('user', key); // This would typically be fetched or generated dynamically
        formData.append('title', title);
        formData.append('content_type', 'video');
        formData.append('video', video);

        const url = id
            ? `/digiboard/videos/${id}/?key=${key}`
            : `/digiboard/videos/?key=${key}`;
        const message = id
            ? 'Video updated Successfully'
            : 'Video added Successfully';
        const method = id ? 'put' : 'post'; // Use 'put' for update and 'post' for create
        try {
            await axiosInstance[method](url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            navigate(`/${key}/addcontent`);
            showSuccess(message);
        } catch (error) {
            if (error.response && error.response.data) {
                showError(error.response.data);
            }
        } finally {
            setLoading(false);
        }
    };

    const fetchItem = useCallback(async () => {
        try {
            const response = await axiosInstance.get(
                `/digiboard/content/${id}/?key=${key}`
            );
            const { title } = response.data;
            setTitle(title);
        } catch (e) {
            console.log(e);
        }
    }, [id, key]);

    useEffect(() => {
        if (id) {
            fetchItem();
        }
    }, [fetchItem, id]);

    const cookieKey = getCookie('key');
    useEffect(() => {
        if (!(key === cookieKey)) {
            clearCookie('token');
            navigate(`/${key}`);
        }
    }, [key, cookieKey, navigate]);

    return (
        <Container>
            <Box
                mt={8}
                maxWidth={'730px'}
                sx={{
                    p: 1.5,
                    mx: 'auto',
                    borderLeft: '4px solid #46A957',
                    background: '#F9F9F9',
                }}>
                <Grid container display='flex' alignItems='center'>
                    <Grid item lg={4} xs={12}>
                        <Typography px={2}>Überschrift</Typography>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <TextField
                            fullWidth
                            placeholder='Überschrift'
                            variant='outlined'
                            size='small'
                            autoComplete='off'
                            multiline
                            value={title}
                            onChange={handleTitleChange}
                            disabled={loading} // Disable the input field while loading
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sx={{ mt: { xs: 4, lg: 4 } }}>
                        <Typography px={2}>Video</Typography>
                    </Grid>
                    <Grid item lg={8} xs={12} sx={{ mt: { xs: 0, lg: 4 } }}>
                        <TextField
                            fullWidth
                            type='file'
                            size='small'
                            onChange={handleVideoChange}
                            disabled={loading} // Disable the input field while loading
                        />
                    </Grid>
                </Grid>
                <Box textAlign='center'>
                    <Button
                        variant='outlined'
                        sx={{ mt: 6, width: '40%', fontWeight: 'bold' }}
                        onClick={handleSubmit}
                        disabled={loading} // Disable the button while loading
                    >
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : id ? (
                            'Speichern'
                        ) : (
                            'Speichern'
                        )}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Video;
