import React, { useRef } from 'react';
import { Box, Container } from '@mui/material';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const PhotowallSlider = ({ images }) => {
    const sliderRef = useRef(null);

    const handleDoubleClick = () => {
        if (sliderRef.current) {
            if (sliderRef.current.requestFullscreen) {
                sliderRef.current.requestFullscreen();
            } else if (sliderRef.current.mozRequestFullScreen) {
                /* Firefox */
                sliderRef.current.mozRequestFullScreen();
            } else if (sliderRef.current.webkitRequestFullscreen) {
                /* Chrome, Safari & Opera */
                sliderRef.current.webkitRequestFullscreen();
            } else if (sliderRef.current.msRequestFullscreen) {
                /* IE/Edge */
                sliderRef.current.msRequestFullscreen();
            }
        }
    };

    return (
        <Container sx={{ mb: 2 }}>
            <Box
                ref={sliderRef}
                sx={{
                    maxWidth: '800px',
                    mx: 'auto',
                    boxShadow: 3,
                    '@media (max-width: 600px)': {
                        maxWidth: '100%', // Full width on small devices
                    },
                }}
                onDoubleClick={handleDoubleClick}>
                <AwesomeSlider>
                    {images.map((img, index) => (
                        <div key={index} data-src={img.image} />
                    ))}
                </AwesomeSlider>
            </Box>
        </Container>
    );
};

export default PhotowallSlider;
