import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Divider,
    IconButton,
    CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Image from './Image';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axiosInstance from './axiosInstance';
import Login from '../pages/login';
import { clearCookie, getCookie } from './Cookies';

const Navbar = () => {
    const { key } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [showLogin, setShowLogin] = useState(false);
    const [hovered, setHovered] = useState(false);
    const fileInputRef = useRef(null);
    const [logo, setLogo] = useState(null);
    const [loading, setLoading] = useState(true);

    const checkPassword = async function () {
        try {
            const response = await axiosInstance.post('user/has-password/', {
                username: key,
            });

            const { password } = response.data;
            const oldkey = getCookie('key');
            const token = getCookie('token');

            if (password) {
                if (!token) {
                    setShowLogin(true);
                }
                if (!(oldkey === key)) {
                    navigate(`/${key}`);
                    clearCookie('token');
                    setShowLogin(true);
                }
                if (oldkey === key && key) {
                    navigate(`/${key}/addcontent`);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const isAddContentPage = location.pathname === `/${key}/addcontent`;

    const handleEditClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async event => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('logo', file);

            try {
                await axiosInstance.patch(`user/logo/?key=${key}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                const response = await axiosInstance.get(
                    `user/logo/?key=${key}`
                );
                setLogo(response.data.logo);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    };

    const fetchLogo = useCallback(
        async function () {
            try {
                const response = await axiosInstance.get(
                    `user/logo/?key=${key}`
                );
                setLogo(response.data.logo);
                setLoading(false);
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
        },
        [key]
    );

    useEffect(() => {
        fetchLogo();
    }, [fetchLogo]);

    const handleDeleteClick = async () => {
        try {
            await axiosInstance.patch(`user/logo/?key=${key}`, { logo: null });
            setLogo(null); // Remove the logo from the state
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box sx={{ background: '#F9F9F9' }}>
            <Container>
                <Box
                    p={1.5}
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'>
                    <Box>
                        <Button variant='outlined' onClick={checkPassword}>
                            Edit
                        </Button>
                    </Box>
                    <Box
                        position='relative'
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                {!logo && (
                                    <Image
                                        name='logo.png'
                                        sx={{ width: 150, height: 61 }}
                                    />
                                )}
                                {logo && (
                                    <Box sx={{ maxWidth: '100%', ml: 2 }}>
                                        <img
                                            src={`${logo}`}
                                            alt='noimage'
                                            style={{
                                                width: 150,
                                                height: 61,
                                            }}
                                        />
                                    </Box>
                                )}
                            </>
                        )}
                        {hovered && isAddContentPage && (
                            <Box
                                position='absolute'
                                top='0'
                                left='0'
                                width='100%'
                                height='100%'
                                display='flex'
                                alignItems='center'
                                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                                justifyContent='center'>
                                <IconButton
                                    color='primary'
                                    onClick={handleEditClick}
                                    sx={{
                                        bgcolor: 'rgba(0, 0, 0, 0.3)',
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: 'rgba(0, 0, 0, 0.3)',
                                        },
                                    }}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        bgcolor: 'rgba(255, 0, 0, 0.1)',
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: 'rgba(255, 0, 0, 0.1)',
                                        },
                                    }}
                                    onClick={handleDeleteClick}>
                                    <DeleteIcon />
                                </IconButton>
                                <input
                                    type='file'
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Container>
            <Divider />
            {showLogin && <Login />}
        </Box>
    );
};

export default Navbar;
