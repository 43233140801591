function env(name) {
    const nodeENV = process.env.NODE_ENV.toUpperCase();

    return (
        process.env[`REACT_APP_${nodeENV}_${name}`] ||
        process.env[`REACT_APP_${name}`]
    );
}

export { env };
