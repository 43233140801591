// GuestbookModal.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    CircularProgress,
} from '@mui/material';
import axiosInstance from '../components/axiosInstance';
import useMessage from '../components/useMessage';
import { useParams } from 'react-router-dom';

const GuestbookModal = ({ open, onClose, contentId, fetchItems }) => {
    const { showSuccess, showError } = useMessage();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const { key } = useParams();

    useEffect(() => {
        setTitle('');
        setDescription('');
    }, [open]);

    const handleTitleChange = event => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = event => {
        setDescription(event.target.value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const data = {
            user: key,
            title: title,
            content_type: 'guestbook',
            description: description,
            parent_id: contentId, // Pass the parent content ID to link sub-guestbook
        };

        try {
            await axiosInstance.post(
                `/digiboard/content/${contentId}/guestbook-entry/`,
                data
            );
            showSuccess('Guestbook added successfully.');
            onClose();
            fetchItems();
        } catch (error) {
            showError('Failed to add Guestbook. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby='guestbook-modal-title'
            aria-describedby='guestbook-modal-description'>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    p: 4,
                }}>
                <Typography variant='h6' component='h2' gutterBottom>
                    Add Guestbook
                </Typography>
                <TextField
                    fullWidth
                    label='Überschrift'
                    variant='outlined'
                    size='small'
                    margin='normal'
                    value={title}
                    onChange={handleTitleChange}
                />
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label='text'
                    variant='outlined'
                    size='small'
                    margin='normal'
                    value={description}
                    onChange={handleDescriptionChange}
                />
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Button
                        sx={{ textTransform: 'capitalize' }}
                        variant='contained'
                        color='primary'
                        onClick={handleSubmit}
                        disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Speichern'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default GuestbookModal;
