import React, { useReducer } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const snackReducer = (state, action) => {
    if (!action) return { show: false, severity: state.severity || 'info' };
    const severity = Object.keys(action)[0];
    const message = Object.values(action)[0];
    return { show: true, severity, message };
};

const useSnack = () => {
    const [snack, showMessage] = useReducer(snackReducer, {
        show: false,
        severity: 'info',
    });

    return {
        SnackBar: (
            <Snackbar
                open={snack.show}
                autoHideDuration={3000}
                onClose={() => showMessage(null)}>
                <Alert
                    onClose={() => showMessage(null)}
                    severity={snack.severity}>
                    {snack.message}
                </Alert>
            </Snackbar>
        ),
        showMessage,
    };
};

export default useSnack;
