import axios from 'axios';
import { getCookie } from './Cookies';
import { env } from '../utilities/function';

const axiosInstance = axios.create({
    baseURL: env('SERVER'),
});

axiosInstance.interceptors.request.use(
    function (config) {
        const token = getCookie('token');
        if (token) {
            config.headers.Authorization = `token ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default axiosInstance;
