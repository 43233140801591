import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import Addcontent from './pages/Edit';
import Textdata from './pages/AddData/textdata';
import Picture from './pages/AddData/Picture';
import Video from './pages/AddData/Video';
import Photowall from './pages/AddData/Photowall';
import Guestbook from './pages/AddData/Guestbook';
import PhotowallSlider from './pages/PhotowallSlider';
import Login from './pages/login';
import './App.css';
import Header from './components/Header';

const App = () => {
    return (
        <Routes>
            <Route
                path='/:key/*'
                element={
                    <>
                        <Header>
                            <Routes>
                                <Route path='/' element={<Main />} />
                                <Route
                                    path='addcontent'
                                    element={<Addcontent />}
                                />
                                <Route
                                    path='photowallslider'
                                    element={<PhotowallSlider />}
                                />
                                <Route
                                    path='addcontent/text/:id?'
                                    element={<Textdata />}
                                />
                                <Route
                                    path='addcontent/picture/:id?'
                                    element={<Picture />}
                                />
                                <Route
                                    path='addcontent/video/:id?'
                                    element={<Video />}
                                />
                                <Route
                                    path='addcontent/photowall/:id?'
                                    element={<Photowall />}
                                />
                                <Route
                                    path='addcontent/guestbook/:id?'
                                    element={<Guestbook />}
                                />
                            </Routes>
                        </Header>
                    </>
                }
            />
            <Route path='/login' element={<Login />} />
        </Routes>
    );
};

export default App;
