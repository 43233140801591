import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import ReactQuill from 'react-quill';
import './quill.css';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../components/axiosInstance';
import { clearCookie, getCookie } from '../../components/Cookies';

const Textdata = () => {
    const navigate = useNavigate(); // Step 1: Initialize useNavigate
    const { id, key } = useParams();

    // State to manage input values
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();

    // Handle changes in the text field and Quill editor
    const handleTitleChange = event => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = value => {
        setDescription(value);
    };

    // Submit the data via an API call using axios
    const handleSubmit = async () => {
        const data = {
            user: key,
            title: title,
            content_type: 'text',
            description: description,
        };
        const url = id
            ? `/digiboard/content/${id}/?key=${key}`
            : `/digiboard/content/?key=${key}`;
        const method = id ? 'put' : 'post'; // Use 'put' for update and 'post' for create

        try {
            const response = await axiosInstance[method](url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Success:', response.data);
            navigate(`/${key}/addcontent`);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchItem = useCallback(
        async function () {
            try {
                const response = await axiosInstance.get(
                    `/digiboard/content/${id}/?key=${key}`
                );
                const { title, description } = response.data;
                setTitle(title);
                setDescription(description);
            } catch (e) {
                console.log(e);
            }
        },
        [id, key]
    );

    useEffect(() => {
        id && fetchItem();
    }, [fetchItem, id]);

    const cookieKey = getCookie('key');
    useEffect(() => {
        if (!(key === cookieKey)) {
            clearCookie('token`');
            navigate(`/${key}`);
        }
    });

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link'],
            ['clean'],
        ],
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
    ];

    return (
        <Container>
            <Box
                mt={8}
                maxWidth={'730px'}
                sx={{
                    p: 1.5,
                    mx: 'auto',
                    borderLeft: '4px solid #46A957',
                    background: '#F9F9F9',
                }}>
                <Grid container display='flex' alignItems='center'>
                    <Grid item lg={4} xs={12}>
                        <Typography px={2}>Überschrift</Typography>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <TextField
                            fullWidth
                            placeholder='Title'
                            variant='outlined'
                            autoComplete='off'
                            multiline
                            rows={3}
                            value={title}
                            onChange={handleTitleChange}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sx={{ mt: { xs: 4, lg: 4 } }}>
                        <Typography px={2}>Text</Typography>
                    </Grid>
                    <Grid item lg={8} xs={12} sx={{ mt: { xs: 0, lg: 4 } }}>
                        <ReactQuill
                            value={description}
                            modules={modules}
                            formats={formats}
                            onChange={handleDescriptionChange}
                            className='.richtextWrap'
                        />
                    </Grid>
                </Grid>
                <Box textAlign='center'>
                    <Button
                        variant='outlined'
                        sx={{ mt: 10, width: '40%', fontWeight: 'bold' }}
                        onClick={handleSubmit}>
                        Speichern
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Textdata;
