import {
    Box,
    Button,
    CircularProgress,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../components/axiosInstance';
import { setCookie } from '../components/Cookies';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

const Login = () => {
    const { key } = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true); // Set modal to be open by default
    const [error, setError] = useState('');
    const [username] = useState(key);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await axiosInstance.post(
                `/api-token-auth/`, // Your authentication endpoint
                {
                    username,
                    password,
                }
            );
            const { token } = response.data;
            setCookie('token', token); // Set token in cookie
            setCookie('key', key);

            setOpen(false); // Close the login modal upon successful login
            navigate(`/${key}/addcontent`);
        } catch (err) {
            setError('Incorrect username or password'); // Handle incorrect credentials error
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordChange = event => {
        setPassword(event.target.value);
    };

    return (
        <Modal
            open={open}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box sx={style}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                    Enter Password
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        size='small'
                        id='password-input'
                        label='Password'
                        type='password'
                        fullWidth
                        value={password}
                        onChange={handlePasswordChange}
                        margin='normal'
                    />
                    {error && (
                        <Typography color='error' variant='body2'>
                            {error}
                        </Typography>
                    )}
                    <Button
                        size='small'
                        sx={{ mt: 2 }}
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={loading}
                        endIcon={loading && <CircularProgress size={20} />}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default Login;
