import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Container,
    Divider,
    Typography,
    Modal,
    Card,
    IconButton,
} from '@mui/material';
import DOMPurify from 'dompurify';
import axiosInstance from '../components/axiosInstance';
import { useParams } from 'react-router-dom';
import { clearCookie, getCookie, setCookie } from '../components/Cookies';
import PhotowallSlider from './PhotowallSlider';
import GuestbookModal from './GuestbookModal'; // Import the GuestbookModal component
import AddIcon from '@mui/icons-material/Add'; // Import the AddIcon

const Main = () => {
    const [contents, setContents] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { key } = useParams();
    const cookieKey = getCookie('key');
    const [showGuestbookModal, setShowGuestbookModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState(null);

    const fetchItems = useCallback(async () => {
        try {
            const response = await axiosInstance.get(
                `/digiboard/content/?key=${key}`
            );
            setContents(response.data);
            setCookie('key', key);
        } catch (e) {
            if (e.response) {
                if (e.response.status === 403 || e.response.status === 401) {
                    clearCookie('token');
                    setErrorMessage(
                        'You do not have permission to perform this action.'
                    );
                    setShowErrorModal(true);
                } else {
                    console.log(e);
                }
            } else {
                console.log(e);
            }
        }
    }, [key]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    useEffect(() => {
        if (!(key === cookieKey)) {
            clearCookie('token');
        }
    });

    const contentType = {
        text: 'description',
        image: 'file',
        video: 'file',
        photowall: 'file',
        guestbook: 'description',
    };

    const getContent = content => {
        const field = contentType[content.content_type];
        return content[field];
    };

    const openGuestbookModal = content => {
        setSelectedContent(content);
        setShowGuestbookModal(true);
    };

    const closeGuestbookModal = () => {
        setSelectedContent(null);
        setShowGuestbookModal(false);
    };

    return (
        <>
            <Container sx={{ my: 2 }}>
                {contents.map(content => (
                    <Box
                        key={content.id}
                        mt={3}
                        maxWidth={'730px'}
                        sx={{
                            mx: 'auto',
                            borderLeft: '4px solid #46A957',
                            background: '#F9F9F9',
                            p: 2,
                            position: 'relative',
                        }}>
                        <Box
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ py: 2 }}>
                            <Typography variant='h4' sx={{ color: '#46A957' }}>
                                {content.title}
                            </Typography>
                            {content.content_type === 'guestbook' && (
                                <IconButton
                                    onClick={() => openGuestbookModal(content)}
                                    sx={{
                                        textTransform: 'capitalize',
                                        ml: 1,
                                    }}>
                                    <AddIcon />
                                </IconButton>
                            )}
                        </Box>
                        <Divider />
                        <Box mt={3}>
                            {content.content_type === 'text' ||
                            content.content_type === 'guestbook' ? (
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            getContent(content)
                                        ),
                                    }}
                                />
                            ) : content.content_type === 'image' ||
                              content.content_type === 'photowall' ? (
                                content.files && (
                                    <PhotowallSlider images={content.files} />
                                )
                            ) : content.content_type === 'video' ? (
                                <Box sx={{ maxWidth: '100%' }}>
                                    {content.videos.map((vid, vidIndex) => (
                                        <video
                                            key={vidIndex}
                                            controls
                                            width={600}
                                            style={{ maxWidth: '100%' }}>
                                            <source
                                                src={`${vid.video_file}`}
                                                type='video/mp4'
                                            />
                                            Your browser does not support the
                                            video tag.
                                        </video>
                                    ))}
                                </Box>
                            ) : null}
                        </Box>
                        {content.content_type === 'guestbook' &&
                            content.guestbook_entries.length > 0 &&
                            content.guestbook_entries.map(entry => (
                                <Card variant='outlined' sx={{ mt: 2, p: 1 }}>
                                    <Typography
                                        key={entry.id}
                                        fontWeight='bold'>
                                        {entry.title}
                                    </Typography>
                                    <Typography key={entry.id} variant='body1'>
                                        {entry.description}
                                    </Typography>
                                </Card>
                            ))}
                    </Box>
                ))}
            </Container>

            {/* Error Modal */}
            <Modal
                open={showErrorModal}
                onClose={() => setShowErrorModal(false)}
                aria-labelledby='error-modal-title'
                aria-describedby='error-modal-description'>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        p: 4,
                    }}>
                    <Typography
                        id='error-modal-title'
                        variant='h6'
                        component='h2'>
                        Error
                    </Typography>
                    <Typography id='error-modal-description' sx={{ mt: 2 }}>
                        {errorMessage}
                    </Typography>
                </Box>
            </Modal>

            {/* Guestbook Modal */}
            <GuestbookModal
                open={showGuestbookModal}
                onClose={closeGuestbookModal}
                contentId={selectedContent?.id}
                fetchItems={fetchItems}
            />
        </>
    );
};

export default Main;
