import { useContext } from 'react';
import { SnackContext } from './Header'; // Adjust the import path

const useMessage = () => {
    const context = useContext(SnackContext);

    if (!context) {
        throw new Error(
            'useMessage must be used within a SnackContext.Provider'
        );
    }

    const { showMessage } = context;

    const showSuccess = msg => {
        showMessage({ success: msg });
    };

    const showError = msg => {
        showMessage({ error: msg });
    };

    return { showError, showSuccess };
};

export default useMessage;
