import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../components/axiosInstance';
import { clearCookie, getCookie } from '../../components/Cookies';
import useMessage from '../../components/useMessage';

const Guestbook = () => {
    const navigate = useNavigate();
    const { id, key } = useParams();
    const { showError, showSuccess } = useMessage();

    const [title, setTitle] = useState('');
    const [infoText, setInfoText] = useState('');
    const [loading, setLoading] = useState(false);

    const handleTitleChange = event => {
        setTitle(event.target.value);
    };

    const handleInfoTextChange = event => {
        setInfoText(event.target.value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const data = {
            user: key, // This would typically be fetched or generated dynamically
            title: title,
            content_type: 'guestbook',
            description: infoText,
        };

        const url = id
            ? `/digiboard/content/${id}/?key=${key}`
            : `/digiboard/content/?key=${key}`;
        const method = id ? 'put' : 'post'; // Use 'put' for update and 'post' for create
        const message = id
            ? 'Guestbook updated Successfully'
            : 'Guestbook added Successfully';

        try {
            await axiosInstance[method](url, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            navigate(`/${key}/addcontent`);
            showSuccess(message);
        } catch (error) {
            if (error.response && error.response.data) {
                showError(error.response.data);
            }
        } finally {
            setLoading(false);
        }
    };

    const fetchItem = useCallback(
        async function () {
            try {
                const response = await axiosInstance.get(
                    `/digiboard/content/${id}/?key=${key}`
                );
                const { title, description } = response.data;
                setTitle(title);
                setInfoText(description);
            } catch (e) {
                console.log(e);
            }
        },
        [id, key]
    );

    useEffect(() => {
        id && fetchItem();
    }, [fetchItem, id]);

    const cookieKey = getCookie('key');
    useEffect(() => {
        if (!(key === cookieKey)) {
            clearCookie('token`');
            navigate(`/${key}`);
        }
    });

    return (
        <Container>
            <Box
                mt={8}
                maxWidth={'730px'}
                sx={{
                    p: 1.5,
                    mx: 'auto',
                    borderLeft: '4px solid #46A957',
                    background: '#F9F9F9',
                }}>
                <Grid container display='flex'>
                    <Grid item lg={4} xs={12}>
                        <Typography px={2}>Überschrift</Typography>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <TextField
                            fullWidth
                            placeholder='Guestbook for XY'
                            variant='outlined'
                            size='small'
                            autoComplete='off'
                            multiline
                            value={title}
                            onChange={handleTitleChange}
                            disabled={loading} // Disable the input field while loading
                        />
                    </Grid>
                    <Grid item lg={4} xs={12} sx={{ mt: { xs: 4, lg: 4 } }}>
                        <Typography px={2}>Text</Typography>
                    </Grid>
                    <Grid item lg={8} xs={12} sx={{ mt: { xs: 0, lg: 4 } }}>
                        <TextField
                            fullWidth
                            placeholder='Here you can give the information about what should be done...'
                            variant='outlined'
                            autoComplete='off'
                            multiline
                            rows={5}
                            value={infoText}
                            onChange={handleInfoTextChange}
                            disabled={loading} // Disable the input field while loading
                        />
                    </Grid>
                </Grid>
                <Box textAlign='center'>
                    <Button
                        variant='outlined'
                        sx={{ mt: 6, width: '40%', fontWeight: 'bold' }}
                        onClick={handleSubmit}
                        disabled={loading} // Disable the button while loading
                    >
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : id ? (
                            'Speichern'
                        ) : (
                            'Speichern'
                        )}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Guestbook;
